html {
  font-family: "Amazon Ember, Helvetica Neue,Roboto,Arial, sans-serif";
}

.metricTimePicker > div {
  width: 165px;
  height: 36px;
  font-size: 13px;
  padding: 10px 2px 7px 2px;
}

.x6-graph-scroller::-webkit-scrollbar {
  overflow: auto;
  height: 0px;
  color: transparent;
  background: transparent;
}

.MuiInputBase-root > .MuiTablePagination-selectIcon {
  top: 3px;
}

.apexcharts-zoom-icon {
  display: none;
}

.apexcharts-menu-icon > svg {
  display: none;
}

.apexcharts-menu-icon {
  margin-top: 0.1rem;
  margin-left: 4px;
  background-color: #6e8192;
  mask-image: url("assets/ic_more.svg");
}

.apexcharts-menu-item.exportSVG {
  display: none;
}

.apexcharts-menu-item.exportPNG {
  display: none;
}

.apexcharts-menu-item.exportCSV {
  line-height: 0;
  visibility: hidden;
}

.apexcharts-menu-item.exportCSV::after {
  content: "下载CSV文件";
  line-height: 1;
  visibility: visible;
}

.apexcharts-menu.apexcharts-menu-open {
  border-radius: 6px;
  background-color: transparent;
}

.h_iframe {
  position: relative;
}

.h_iframe .ratio {
  display: block;
  width: 100%;
  height: auto;
}

.h_iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.userguide {
  /* height: calc(100vh - 0px);
  width: calc(100vw - 0px);
  box-sizing: border-box; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: None;
}

.powerbi {
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  height: calc(100vh - 120px);
  border: None;
}

/* .react-player {
  border-radius: 12px;
  overflow: hidden;
} */

/* .MuiOutlinedInput-input::-webkit-scrollbar {
  overflow: auto;
  width: 0.4rem;
}

.MuiOutlinedInput-input::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
}

.MuiOutlinedInput-input::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0.2rem;
} */

/* outline: 1px solid slategrey; */
